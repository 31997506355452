.supafolio-loader-container {
    .supafolio-loader {
        border-top-color: $primary-color;
    }
}
.book-detail {
    .book-content {
        &__top {
            padding-top: 24px;
            @media (min-width: 768px) {
                padding-top: 64px;
            }
            background: $light-grey;
            @include full_width_colour($light-grey);
        }
    }
    h1 {
        color: $secondary-color;
    }
    h3 {
        @extend %main-font;
    }
    .flextabs-container {
        background: $light-grey;
        @include full_width_colour($light-grey);
    }
    .flextabs__tab button {
        text-transform: uppercase;
        font-weight: bold;
    }
    .buy-button-wrapper {
        background: $white;
        border: 3px solid $dark-grey;
        .retailers-wrapper {
            border: 1px solid $dark-grey;
        }
        .format {
            font-size: 25px;
        }
        .price {
            font-size: 20px;
        }
    }

    .button-asset {
        padding: 0;
        a {
            display: block;
            color: $white;
            padding: 15px 30px;
        }
        &--audio {
            padding: 10px 30px;
        }
    }
    .related-books {
        &-title {
            h1 {
                padding-top: 30px;
                border-top: 3px solid $gold;
                font-size: 30px;
            }
        }
    }
}
