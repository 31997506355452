.home-blockquote {
  blockquote {
    color: $white;
  }
  .tns-outer {
    @include breakpoint(large-handset-landscape) {
      margin: 0 30px;
    }
    .tns-controls {
      [data-controls="prev"] {
        background-image: url("../img/arrow-white-prev.png");
      }
      [data-controls="next"] {
          background-image: url("../img/arrow-white-next.png");
      }
    }
  }
}


.hero__container--left  {
  @include breakpoint(large-handset-landscape) {
    &.md\:w-2\/5 {
      width: 60%;
    }
  }
  h1 {
    @include breakpoint(large-tablet) {
      font-size: 48px;
      line-height: 58px;
      
    }
  }

  h2 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    @include breakpoint(large-tablet) {
      font-size: 30px;
      line-height: 40px;
    }
  }
}

.hero__container--right  {
  @include breakpoint(large-handset-landscape) {
    &.md\:w-3\/5 {
      width: 40%;
    }
  }
}