@import "variables";
@import "typography";

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.primary-background {
    background-color: $primary-color;
    @include full_width_colour($primary-color);
}

.secondary-background {
    background-color: $secondary-color;
    @include full_width_colour($secondary-color);
}


.light-grey-background { 
    background-color: $light-grey; 
    @include full_width_colour($light-grey);
}

.dark-grey-background { 
    background-color: $dark-grey; 
    @include full_width_colour($dark-grey);
}

.red-background {
    background-color: $red; 
    @include full_width_colour($red);
}