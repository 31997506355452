.header {
  background-color: $header-background-color;
  background-size: cover;
  &__logo {
    padding: 70px 0 10px 0;
    @include breakpoint(sm) {
      padding: 30px 0;
    }
  }
}
