@font-face {
    font-family: "whiffyregular";
    src: url('../fonts/whiffy-webfont.woff2') format('woff2'),
    url('../fonts/whiffy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}




%primary-font {
    font-family: "whiffyregular";
    text-transform: uppercase;
}

%main-font {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: $base-font-size;
    line-height: $base-font-size + 5;
    text-transform: none;
}

h1,
.primary-font {
    @extend %primary-font;
}

.primary-text-colour {
    color: $primary-color;
}

h2,
h3,
.secondary-font {
    @extend %primary-font;
}

.secondary-text-colour {
    color: $secondary-color;
}

h4,
h5,
body,
.main-font {
    @extend %main-font;
}

.main-text-colour {
    color: $body-font-color;
}

h1 {
    font-size: $h1-font-size;
    line-height: $h1-font-size + 5;
    margin-bottom: 20px;
}

h2 {
    font-size: $h2-font-size;
    line-height: $h2-font-size;
    margin-bottom: 10px;
}

h3 {
    font-size: $h3-font-size;
    line-height: $h3-font-size;
    margin-bottom: 10px;
    margin-top: 20px;
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 10px;
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 10px;
}

blockquote {
    font-size: 30px;
    line-height: 48px;
    @extend %primary-font;
    cite {
        font-style: normal;
        text-transform: none;
        margin-top: 20px;
        display: block;
        font-size: 18px;
        line-height: 20px;
    }
}

.pagination {
    li {
        a {
            color: $secondary-color;
        }
    }
}

.separator {
    padding-top: 30px;
    border-top: 3px solid $section-border-top;
}

.separator-decorative {
    width: 100%;
    &::before {
        content: "";
        height: 80px;
        background: url("https://supadu-io.imgix.net/harpercollins-mousecircus/mousecircus-site/separator-decorative.png?auto=format") center;
        width: 100%;
        background-repeat: no-repeat;
        background-size: auto;
        display: block;
        margin: 0 auto;
    }
}

a {
    color: $body-font-color;
    &:hover {
        color: $secondary-color;
        cursor: pointer;
    }
}

p {
    margin-bottom: 1em;
}

.quiz-button {
    @extend %secondary-font;
    @include siteButton($secondary-color, $white);
}

.primary-button {
    @extend %primary-font;
    @include siteButton($primary-color, $white);
    &:hover {
        background-color: $gold;
        color: $white;
    }
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.secondary-button {
    @extend %primary-font;
    @include siteButton($secondary-color, $white);
    &:hover {
        background-color: $red;
        color: $white;
    }
    &__character {
        display: inline-block;
        text-align: center;
    }
}

.white-button {
    @extend %secondary-font;
    @include siteButton($white, $secondary-color);
}

.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    margin: 20px 0;
    a {
        &:hover {
            color: $black;
        }
    }
}
