$gold: #a88e5c;
$red: #c4292f;
$white: white;
$max-width: 1060px;
$grey: #5f5f5f;
$light-grey: #eae9e8;
$dark-grey: #2d2b2b;
$black: #000;
$purple: #633660;
$blue: #3e7196;
$yellow: #c89144;
$animation-time: 0.3s;
$section-border-top: $gold;
$background-body: $white;
$body-font-color: $black;
$primary-color: $red;
$secondary-color: $gold;
$background-buy-section: $dark-grey;


/* Header */

$header-background-color: $white;

/* NAVIGATION */

$nav-links-colour: $white;
$nav-links-colour-hover: $black;
$nav-current-link-colour: $black;
$nav-background-colour: $primary-color;

/* default font sizes */

$base-font-size: 16px;
$h1-font-size: 35px;
$h2-font-size: 30px;
$h3-font-size: 25px;
$h4-font-size: 18px;
$h5-font-size: 16px;
